import React, { useState, useEffect } from "react";
import "./App.css";

export default function CodeSnippet({ color, apiKey, format, useCase }) {
  const [generatedValue, setGeneratedValue] = useState(
    "Run this code to generate a new value"
  );
  const [codeSnippet, setCodeSnippet] = useState("default");
  const [language, setLanguage] = useState("python");
  const LOCAL_API_URL = "http://localhost:8787";
  const PROD_API_URL = "https://spring-feather-e533.sam-ec1.workers.dev";

  useEffect(() => {
    buildCodeSnippet();
  }, [apiKey, color, language, format, useCase]);

  async function callApi() {
    if (apiKey === "") {
      setGeneratedValue("Create API key to generate values");
      return;
    }

    try {
      const response = await fetch(`${PROD_API_URL}/random?format=${format}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-API-KEY": apiKey,
        },
      });
      const data = await response.json();
      setGeneratedValue(data.value);
    } catch (error) {
      setGeneratedValue("Too many requests. Wait a bit and try again.");
    }
  }

  function buildCodeSnippet() {
    if (language === "python") {
      let code = `<span class="top-${color}">import requests</span>\n\n`;
      code += `<span class="middle-${color}">api_key = "${apiKey}"\n</span>`;
      code += `<span class="middle-${color}">headers= {"x-api-key": api_key}</span>\n`;
      code += `<span class="middle-${color}">url = "https://spring-feather-e533.sam-ec1.workers.dev/random?format=${format}"</span>\n\n`; // &use_case=${useCase}
      code += `<span class="bottom-${color}">response = requests.get(url, headers=headers)</span>\n`;
      code += `<span class="bottom-${color}">print(response.json())</span>\n`;
      setCodeSnippet(code);
    } else if (language === "javascript") {
      let code = `<span class="top-${color}">const apiKey = "${apiKey}";\n</span>`;
      code += `<span class="top-${color}">const url = "https://spring-feather-e533.sam-ec1.workers.dev/random?format=${format}";\n\n</span>`; // &use_case=${useCase}
      code += `<span class="middle-${color}">fetch(url, { headers: { "x-api-key": apiKey } })\n</span>`;
      code += `<span class="middle-${color}">\t.then(response => response.json())\n</span>`;
      code += `<span class="middle-${color}">\t.then(data => console.log(data))\n</span>`;
      setCodeSnippet(code.replace(/\n/g, "<br />"));
    } else if (language === "rust") {
      let code = `<span class="top-${color}">use reqwest::blocking::get;\n</span>`;
      code += `<span class="top-${color}">use std::collections::HashMap;\n\n</span>`;
      code += `<span class="middle-${color}">fn main() -> Result<(), Box<dyn std::error::Error>> {\n</span>`;
      code += `<span class="middle-${color}">    let api_key = "${apiKey}";\n</span>`;
      code += `<span class="middle-${color}">    let url = "https://spring-feather-e533.sam-ec1.workers.dev/random?format=${format}";\n\n</span>`; // &use_case=${useCase}
      code += `<span class="middle-${color}">    let client = reqwest::blocking::Client::new();\n</span>`;
      code += `<span class="middle-${color}">    let response: HashMap<String, String> = client\n</span>`;
      code += `<span class="middle-${color}">        .get(url)\n</span>`;
      code += `<span class="middle-${color}">        .header(\"x-api-key\", api_key)\n</span>`;
      code += `<span class="middle-${color}">        .send()?\n</span>`;
      code += `<span class="middle-${color}">        .json()?;\n\n</span>`;
      code += `<span class="middle-${color}">    println!(\"{:?}\", response);\n</span>`;
      code += `<span class="middle-${color}">    Ok(())\n</span>`;
      code += `<span class="middle-${color}">}\n</span>`;
      setCodeSnippet(code.replace(/\n/g, "<br />"));
    } else {
      // treat like python
      setCodeSnippet("default");
    }
  }

  return (
    <div className="relative bg-gray-900 border border-black border-solid mt-3">
      <div className="bg-gray-900 p-4 flex flex-row justify-between items-center border-b border-gray-700">
        <div className="flex flex-row justify-start items-center gap-2">
          <img
            src="./languages/python-logo.png"
            className="w-6 h-6 hover:cursor-pointer"
            onClick={() => {
              setLanguage("python");
            }}
          ></img>
          <img
            src="./languages/javascript-logo.png"
            className="w-6 h-6 hover:cursor-pointer"
            onClick={() => {
              setLanguage("javascript");
            }}
          ></img>
          <img
            src="./languages/rust-logo.svg"
            className="w-7 h-7 hover:cursor-pointer"
            onClick={() => {
              setLanguage("rust");
            }}
          ></img>
        </div>
        <div className="flex flex-row justify-left items-center gap-2">
          <div
            className="material-symbols-outlined text-white text-base hover:cursor-pointer"
            onClick={() => {
              navigator.clipboard.writeText(
                codeSnippet
                  .replace(/<br \/>/g, "\n")
                  .replace(/<\/?span[^>]*>/g, "")
              );
            }}
          >
            content_copy
          </div>
        </div>
      </div>
      <pre className={`p-4 text-white font-mono text-sm language-${language}`}>
        <code
          dangerouslySetInnerHTML={{
            __html: codeSnippet,
          }}
          style={{
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
            overflowWrap: "break-word",
          }}
        ></code>
      </pre>

      <div className="bg-white p-4 flex flex-row justify-between items-center">
        <h1 className="text-sm truncate flex-shrink min-w-0">
          {generatedValue}
        </h1>
        <button
          onClick={callApi}
          className={`flex-shrink-0 ml-2 hover:-translate-y-0.5 duration-200 transition-all ${
            color === "purple"
              ? "bg-[#d9d9ff]"
              : color === "pink"
              ? "bg-[#FFD8FF]"
              : "bg-[#89D9F6]"
          } text-black px-2 py-1 border-black border-solid border`}
        >
          Run this code
        </button>
      </div>
    </div>
  );
}
