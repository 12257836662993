import "./App.css";
import { useState, useEffect } from "react";
import {
  SignedIn,
  SignedOut,
  SignInButton,
  UserButton,
} from "@clerk/clerk-react";
import { useUser } from "@clerk/clerk-react";
import CodeSnippet from "./codesnippet";
import { usePostHog } from "posthog-js/react";
function App() {
  const posthog = usePostHog();

  const [apiKey, setApiKey] = useState("");
  const [plan, setPlan] = useState("");
  const [selectedPlan, setSelectedPlan] = useState("free");
  const [rateLimit, setRateLimit] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [loadingProfile, setLoadingProfile] = useState(true);
  const [lampColor, setLampColor] = useState("blue");
  const [format, setFormat] = useState("hex");
  const [useCase, setUseCase] = useState("randint");

  const { isSignedIn, user, isLoaded } = useUser();
  const LOCAL_API_URL = "http://localhost:8787";
  const PROD_API_URL = "https://spring-feather-e533.sam-ec1.workers.dev";

  function updateFormat(format) {
    setFormat(format);
  }

  async function generateApiKey() {
    if (apiKey !== "") {
      console.log("Regenerating API key");
      const response = await fetch(`${PROD_API_URL}/apikey`, {
        method: "PUT",
        body: JSON.stringify({ email: userEmail, currentApiKey: apiKey }),
        headers: {
          "Content-Type": "application/json",
          "X-API-KEY": process.env.REACT_APP_API_KEY,
        },
      });
      const data = await response.json();
      setApiKey(data.newApiKey);
    } else {
      const response = await fetch(`${PROD_API_URL}/apikey`, {
        method: "POST",
        body: JSON.stringify({ email: userEmail, plan: "free" }),
        headers: {
          "Content-Type": "application/json",
          "X-API-KEY": process.env.REACT_APP_API_KEY,
        },
      });
      const data = await response.json();
      setApiKey(data.apiKey);
    }
  }

  async function getProfileInformation(email) {
    const response = await fetch(
      `${PROD_API_URL}/apikey?email=${encodeURIComponent(email)}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-API-KEY": process.env.REACT_APP_API_KEY,
        },
      }
    );
    const data = await response.json();
    if (data.error === "No API key found for this email") {
      generateNewUserAccount(email);
      setPlan("free");
      setRateLimit("10 requests per second");
      setLoadingProfile(false);
      return;
    }
    setApiKey(data.apiKey);
    setPlan(data.plan);
    setRateLimit(`${data.rateLimit.secondRateLimit} requests per second`);
    setLoadingProfile(false);
  }

  function cycleLampColor() {
    if (lampColor === "blue") {
      setLampColor("purple");
    } else if (lampColor === "purple") {
      setLampColor("pink");
    } else if (lampColor === "pink") {
      setLampColor("blue");
    }
  }

  const [currentLavaLampImage, setCurrentLavaLampImage] = useState("");

  async function getCurrentLavaLampImage(){
    console.log(`${PROD_API_URL}/images`);
    const response = await fetch(`${PROD_API_URL}/images`, {
      method: "GET",
      headers: {
        "X-API-KEY": process.env.REACT_APP_API_KEY,
      },
      cache: "no-store",
    });
    const blob = await response.blob();
    const imageUrl = URL.createObjectURL(blob);
    setCurrentLavaLampImage(imageUrl);
  }

  async function generateNewUserAccount(email) {
    const response = await fetch(
      `${PROD_API_URL}/apikey?email=${encodeURIComponent(email)}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-API-KEY": process.env.REACT_APP_API_KEY,
        },
      }
    );
    const data = await response.json();
    setApiKey(data.apiKey);
  }

  useEffect(() => {
    getCurrentLavaLampImage();
    if (isSignedIn) {
      setUserEmail(user.primaryEmailAddress.emailAddress);
      getProfileInformation(user.primaryEmailAddress.emailAddress);
      posthog?.identify(user.id, {
        email: user.primaryEmailAddress.emailAddress,
        name: user.firstName,
        created_at: user.createdAt,
      });
    }
  }, [isSignedIn]);

  return (
    <div>
      <SignedOut>
        <div className="bg-[#e1e1e1] h-screen w-full px-6 sm:px-12 lg:px-24">
          <div className="flex flex-row justify-end items-center mt-14">
            <SignInButton>
              <button className="bg-black text-white px-6 py-2 text-[#E1E1E1] font-semibold text-opacity-90 text-xl hover:-translate-y-1 duration-200 transition-all">
                Log In
              </button>
            </SignInButton>
          </div>
          <div className="mt-12 flex flex-row justify-start ml-6">
            <div>
              <img
                src="./lamps/black_and_white_lamp.svg"
                alt="Lava Lamps as a Service"
                className="w-12 h-auto hidden lg:block"
              />
            </div>
            <h1 className="text-5xl sm:text-7xl ml-3 font-semibold mt-10 leading-[3rem] sm:leading-[4rem]">
              Lava Lamps as a Service
            </h1>
          </div>
          <div className="ml-10 mt-4">
            <h1 className="text-base sm:text-xl font-normal opacity-70 mr-10 w-3/4">
              Enhance your security with truly unpredictable random number
              generation. Generate unique ids, hashes, API secrets, and more
              using a CSPRNG powered by an array of chaotic lava lamps.
            </h1>
          </div>
          <div className="ml-10 mt-5 flex flex-row justify-start items-center">
            <SignInButton>
              <button className="bg-black text-white px-6 py-2 text-[#E1E1E1] text-xl font-semibold text-opacity-90 hover:-translate-y-1 duration-200 transition-all">
                Try it out
              </button>
            </SignInButton>
          </div>
      
          <div className="flex flex-row justify-center items-center mt-10 mb-2">
              {/* <img src="./IMG_0707.jpg" className="w-48 h-auto border-2 border-black" /> */}
              <img src={currentLavaLampImage} className="w-3/5 h-auto border-2 border-black" />
            </div>
          <div className="flex flex-row justify-center items-center mt-4">
            <span className="relative flex h-4 w-4 hidden sm:inline-flex">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-500 opacity-75"></span>
              <span className="relative inline-flex rounded-full h-4 w-4 bg-red-500 border-2 border-black"></span>
            </span>
            <h1 className="text-lg ml-2.5 text-xl">
              Live from San Francisco, California
            </h1>
          </div>
          <h1 className="text-sm italic text-center">(10 minute delay)</h1>

          <div className="flex flex-col justify-start items-center mb-10">
            <div className="flex flex-row justify-start items-center gap-0"></div>
          </div>
          <div className="mb-40">

          </div>
        
        </div>
      </SignedOut>
      <SignedIn>
        {loadingProfile ? (

          <div className="mx-20 my-20">
            <div className="flex flex-row justify-center items-center">
              <img src="./lamps/black_and_white_lamp.svg" className="mt-20 w-3/4 sm:w-1/4 scale-50 hover:scale-75 duration-200 transition-all" />
            </div>
            <h1 className="text-2xl text-center -mt-20">Loading...</h1>
          </div>
        ) : (
          <div className="mx-20 sm:mx-10">
            <div className="flex flex-row justify-between mx-5 sm:mx-10 mt-10 mb-2">
              <div className="hover:cursor-pointer">
                <img
                  onClick={cycleLampColor}
                  src={
                    lampColor === "purple"
                      ? "./lamps/purple_lava_lamp.svg"
                      : lampColor === "pink"
                      ? "./lamps/pink_lava_lamp.svg"
                      : lampColor === "blue"
                      ? "./lamps/blue_lava_lamp.svg"
                      : "./lamps/black_and_white_lamp.svg"
                  }
                  className="w-1/2 scale-50 hover:scale-75 duration-200 transition-all"
                />
              </div>
              <div className="p-2 scale-125 mt-4">
                <UserButton />
              </div>
            </div>
            <h1 className="text-left text-4xl ml-4 sm:ml-10 font-bold">API key</h1>
            <div className="ml-4 mr-4 sm:mr-10 sm:ml-10 w-4/5">
              <div className="flex flex-row justify-between items-center border border-black px-2 mt-3 bg-white">
                <p className="text-md overflow-hidden text-ellipsis whitespace-nowrap">
                  {apiKey
                    ? apiKey.substring(
                        0,
                        Math.floor((window.innerWidth * 0.5) / 8)
                      ) +
                      (apiKey.length > Math.floor((window.innerWidth * 0.5) / 8)
                        ? "......."
                        : "")
                    : "Create an API key"}
                </p>
                <div className="flex flex-row justify-end items-center">
                  <div>
                    <span
                      className="material-symbols-outlined text-base pt-1 pr-1 pl-1 opacity-90 hover:cursor-pointer"
                      onClick={() => {
                        if (apiKey) {
                          navigator.clipboard.writeText(apiKey);
                        }
                      }}
                    >
                      content_copy
                    </span>
                  </div>
                  <div>
                    <span
                      class="material-symbols-outlined text-base pt-1 opacity-90 hover:cursor-pointer"
                      onClick={generateApiKey}
                    >
                      refresh
                    </span>
                  </div>
                </div>
              </div>
              <h1 className="text-left text-4xl font-bold mt-10">
                Create your code snippet
              </h1>
              <div className="flex flex-row justify-start items-center gap-2 mt-2">
                <button
                  onClick={() => updateFormat("hex")}
                  className={`hover:-translate-y-0.5 duration-200 transition-all mt-2 ${
                    format === "hex"
                      ? "bg-[#343434] text-[#E1E1E1]"
                      : "bg-[#E1E1E1] text-black"
                  } text-md px-2 py-1 border border-black border-solid w-20`}
                >
                  hex
                </button>
                <button
                  onClick={() => updateFormat("base64")}
                  className={`hover:-translate-y-0.5 duration-200 transition-all mt-2 ${
                    format === "base64"
                      ? "bg-[#343434] text-[#E1E1E1]"
                      : "bg-[#E1E1E1] text-black"
                  } text-md px-2 py-1 border border-black border-solid w-20`}
                >
                  base64
                </button>
                <button
                  onClick={() => updateFormat("rawstring")}
                  className={`hover:-translate-y-0.5 duration-200 transition-all mt-2 ${
                    format === "rawstring"
                      ? "bg-[#343434] text-[#E1E1E1]"
                      : "bg-[#E1E1E1] text-black"
                  } text-md px-2 py-1 border border-black border-solid w-20`}
                >
                  rawstring
                </button>
              </div>
              <CodeSnippet
                color={lampColor}
                apiKey={apiKey}
                format={format}
                useCase={useCase}
              />
              <h1 className="text-left text-4xl font-bold mt-10">Plan</h1>
              <div className="flex flex-row justify-start items-center gap-2">
                <button
                  onClick={() => setSelectedPlan("free")}
                  className={`hover:-translate-y-0.5 duration-200 transition-all mt-2 ${
                    selectedPlan === "free"
                      ? "text-[#E1E1E1] bg-[#343434]"
                      : "bg-[#E1E1E1] text-black"
                  } text-md px-2 py-1 border border-black border-solid w-20`}
                >
                  Free
                </button>
                <button
                  onClick={() => setSelectedPlan("starter")}
                  className={`hover:-translate-y-0.5 duration-200 transition-all mt-2 ${
                    selectedPlan === "starter"
                      ? "text-[#E1E1E1] bg-[#343434]"
                      : "bg-[#E1E1E1] text-black"
                  } text-md px-2 py-1 border border-black border-solid w-20`}
                >
                  Starter
                </button>
                <button
                  onClick={() => setSelectedPlan("business")}
                  className={`hover:-translate-y-0.5 duration-200 transition-all mt-2 ${
                    selectedPlan === "business"
                      ? "text-[#E1E1E1] bg-[#343434]"
                      : "bg-[#E1E1E1] text-black"
                  } text-md px-2 py-1 border border-black border-solid w-20`}
                >
                  Business
                </button>
              </div>
              <div className="mt-4 pb-20">
                {selectedPlan === "free" && (
                  <div className="bg-[#E1E1E1] p-4 border border-black border-solid bg-white">
                    <h2 className="text-lg font-semibold">Free Plan</h2>
                    <p>
                      <b>Rate Limit</b>: 10 requests per minute
                    </p>
                    <p>
                      <b>Quota</b>: 10,000 requests per month
                    </p>
                    {plan === "free" ? (
                      <div>
                        <h1 className="p-2 mt-4 bg-[#E6F8FF] text-black w-fit border border-black border-solid">
                          Current Plan
                        </h1>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                )}
                {selectedPlan === "starter" && (
                  <div className="bg-[#E1E1E1] p-4 border border-black border-solid bg-white">
                    <h2 className="text-lg font-semibold">
                      $0.001 per request
                    </h2>
                    <p>
                      <b>Rate Limit</b>: 100 requests per minute
                    </p>
                    <p>
                      <b>Quota</b>: 1M requests per month
                    </p>
                    {plan === "starter" ? (
                      <div>
                        <h1 className="p-2 mt-4 bg-[#E6F8FF] text-black w-fit border border-black border-solid">
                          Current Plan
                        </h1>
                      </div>
                    ) : (
                      <div>
                        <button
                          className="hover:-translate-y-0.5 duration-200 transition-all p-2 mt-4 bg-[#ECECFF] text-black w-fit border border-black border-solid"
                          onClick={() =>
                            (window.location.href =
                              "mailto:crombiesamuel@gmail.com?subject=Upgrade to Starter")
                          }
                        >
                          Upgrade
                        </button>
                      </div>
                    )}
                  </div>
                )}
                {selectedPlan === "business" && (
                  <div className="bg-[#E1E1E1] p-4 border border-black border-solid bg-white">
                    <h2 className="text-lg font-semibold">
                      $0.00075 per request
                    </h2>
                    <p>
                      <b>Rate Limit</b>: 1000 requests per minute
                    </p>
                    <p>
                      <b>Quota</b>: 5M requests per month
                    </p>
                    {plan === "business" ? (
                      <div>
                        <h1 className="p-2 mt-4 bg-[#E6F8FF] text-black w-fit border border-black border-solid">
                          Current Plan
                        </h1>
                      </div>
                    ) : (
                      <div>
                        <button
                          className="hover:-translate-y-0.5 duration-200 transition-all p-2 mt-4 bg-[#ECECFF] text-black w-fit border border-black border-solid"
                          onClick={() =>
                            (window.location.href =
                              "mailto:crombiesamuel@gmail.com?subject=Upgrade to Business")
                          }
                        >
                          Upgrade
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div></div>
            </div>
          </div>
        )}
      </SignedIn>
    </div>
  );
}

export default App;
